exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=default" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=default" /* webpackChunkName: "component---src-pages-index-tsx" */)
}



exports.head = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=head" /* webpackChunkName: "component---src-pages-index-tsxhead" */)
}

